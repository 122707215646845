<template>
  <BaseTableWrapper :columns="columns" :table-init="tableInit">
    <h1 class="page-header">Classifications</h1>
  </BaseTableWrapper>
</template>

<script>
import classifications from '@/services/classifications'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import ClassificationViewDetail from '@/components/ClassificationViewDetail.vue'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'classification'
)

const columns = [
  {
    _t_id: '73320604-e4f8-4c26-8c6b-071285f8de2b',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '261ed72e-f465-4347-9797-323d75a4978c',
    prop: 'classificationId',
    text: 'Classification ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'classificationId',
    defaultSort: true,
  },
  {
    _t_id: 'bbebaa6c-717a-41de-b60e-2f8671d74470',
    prop: 'classificationName',
    text: 'Classification Name',
    sort: false,
    filter: true,
    type: 'text',
    filterType: 'contains',
    filterProp: 'name',
  },
  {
    _t_id: 'f1249b9e-4983-4ab2-b53f-6ed92bb5f570',
    prop: 'classificationType',
    text: 'Classification Type',
    sort: false,
    filter: true,
    type: 'text',
    filterType: 'contains',
    filterProp: 'type',
  },
  {
    _t_id: '54b15cc7-b570-4352-aee7-b99512fdae7a',
    prop: 'parentClassifications',
    text: 'Parent Classifications',
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'contains',
  },
  {
    _t_id: '93eb0ede-ac29-40fd-bffd-1a64bf67df73',
    prop: 'createdOn',
    text: 'Date Added',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'createdOn',
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    predefined: deepClone(datePredefined),
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
    ClassificationViewDetail,
  },
  metaInfo() {
    return {
      title: 'Classifications',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detail: ClassificationViewDetail,
        detailKeyId: 'classificationId',
        tableId: 'classifications_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'classifications.add' }),
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        columns,
        collection: 'Classifications',
        action: (params) => classifications.getClassifications(params),
      },
    }
  },
  async mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
