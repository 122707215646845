<template>
  <v-container>
    <v-layout row wrap>
      <v-flex>
        <v-layout row wrap>
          <template v-if="row.action === 'delete_classification_detail'">
            <BaseDeletion
              :close="closeCard"
              :collection-name="'classification'"
              :row="row"
              :special-identifier="'classificationId'"
              :action="action"
            />
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BaseDeletion from '@/components/BaseDeletion.vue'
import classifications from '@/services/classifications'

export default {
  components: {
    BaseDeletion,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      expanded: this.props.expanded,
      action: (params) => classifications.deleteClassification(params),
    }
  },
  methods: {
    closeCard() {
      this.props.expanded = false
    },
  },
}
</script>
