import { render, staticRenderFns } from "./ClassificationsList.vue?vue&type=template&id=e24a4392&scoped=true"
import script from "./ClassificationsList.vue?vue&type=script&lang=js"
export * from "./ClassificationsList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e24a4392",
  null
  
)

export default component.exports